import React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import IllustrationsList from "../components/illustrations/illustrations-list";

const PageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px;

  @media (max-width: 1024px) {
    padding: 2rem;
  }
`;

const IllustrationsPage = () => {
  return (
    <Layout>
      <SEO title="Xabadu" keywords={[`gatsby`, `application`, `react`]} />
      <PageWrapper>
        <IllustrationsList />
      </PageWrapper>
    </Layout>
  );
};

export default IllustrationsPage;
