import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import { COLORS } from "../../constants";

const BASE_URL = "https://codepen.io/Xabadu/pen/";

const TitleText = styled.h3`
  font-size: 1.3rem;
  @media (max-width: 320px) {
    font-size: 1.2rem;
  }
`;

const styles = theme => ({
  postCard: {
    border: `1px solid ${COLORS.GRAY.LIGHT}`,
    fontFamily: theme.typography.fontFamily
  },
  postTitle: {
    fontFamily: theme.typography.fontFamily,
    height: "3.5rem"
  },
  postLink: {
    color: COLORS.GRAY.DARK,
    textDecoration: "none"
  }
});

class IllustrationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { classes, image, hash, title } = this.props;

    const link = `${BASE_URL}${hash}`;

    return (
      <Card className={classes.postCard}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <CardMedia
            image={image}
            title={title}
            style={{ paddingTop: "56.25%" }}
          />
        </a>
        <Divider variant="middle" />
        <CardContent>
          <a href={link} className="regular-links button-link" target="_blank" rel="noopener noreferrer">
            <TitleText className={classes.postTitle}>{title}</TitleText>
          </a>
          <a href={link} className="regular-links">
            See in CodePen >>
          </a>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(IllustrationCard);
