import React from "react";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import IllustrationCard from "./illustration-card";

import blackLanternCover from "../../images/illustrations/black-lantern.png";
import greenLanternCover from "../../images/illustrations/green-lantern.png";
import laptopCover from "../../images/illustrations/laptop.png";
import redLanternCover from "../../images/illustrations/red-lantern.png";
import orangeLanternCover from "../../images/illustrations/orange-lantern.png";

const list = [
  {
    hash: "mwgJpR",
    image: greenLanternCover,
    title: "Green Lantern"
  },
  {
    hash: "yXrvJK",
    image: blackLanternCover,
    title: "Black Lantern"
  },
  {
    hash: "RgXqMG",
    image: orangeLanternCover,
    title: "Orange Lantern"
  },
  {
    hash: "XqqYZa",
    image: redLanternCover,
    title: "Red Lantern"
  },
  {
    hash: "eRxJMP",
    image: laptopCover,
    title: "Laptop"
  }
];

const styles = () => ({
  gridList: {
    flex: 1
  }
});

const getColumns = width => {
  if (isWidthUp("lg", width)) {
    return 3;
  }
  if (isWidthUp("md", width)) {
    return 2;
  }
  if (isWidthUp("sm", width)) {
    return 2;
  }
  return 1;
};

const IllustrationsList = props => {
  const { classes } = props;
  return (
    <GridList
      className={classes.gridList}
      cellHeight="auto"
      cols={getColumns(props.width)}
      spacing={50}
    >
      {list.map(({ hash, image, title }) => {
        return (
          <GridListTile key={hash} cols={1}>
            <IllustrationCard hash={hash} image={image} title={title} />
          </GridListTile>
        );
      })}
    </GridList>
  );
};

const WithWidth = withWidth()(IllustrationsList);

export default withStyles(styles)(WithWidth);
